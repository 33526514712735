import { template as template_4a8e182930a14ce6a3d15777042dd947 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4a8e182930a14ce6a3d15777042dd947(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
