import { template as template_7c33f446e30b4b3282accb1dda710c05 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { i18n } from "discourse-i18n";
export default class OfflineIndicator extends Component {
    @service
    networkConnectivity;
    get showing() {
        return !this.networkConnectivity.connected;
    }
    @action
    refresh() {
        window.location.reload(true);
    }
    static{
        template_7c33f446e30b4b3282accb1dda710c05(`
    {{#if this.showing}}
      <div class="offline-indicator">
        <span>{{i18n "offline_indicator.no_internet"}}</span>
        <DButton
          @label="offline_indicator.refresh_page"
          @display="link"
          @action={{this.refresh}}
        />
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
