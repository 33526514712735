import { template as template_8bbf324458b14fd586b03885023cc342 } from "@ember/template-compiler";
const FKLabel = template_8bbf324458b14fd586b03885023cc342(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
