import { template as template_2a9f34a9c0ad40f98b79bd297eb0cd15 } from "@ember/template-compiler";
const WelcomeHeader = template_2a9f34a9c0ad40f98b79bd297eb0cd15(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
